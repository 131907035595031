import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box, Flex, Text, Button } from "rebass/styled-components";
import { withTheme } from "styled-components";
import Container from "./UI/Container";
import Icofont from "./UI/Icofont";
import Sticky from "react-stickynode";
import Drawer from "./Drawer";
import Link from "./UI/Link";
import { useStaticQuery, graphql } from "gatsby";
import CustomImage from "./UI/CustomImage";

const NavbarRaw = ({ theme, menusSettings, logoAlt }) => {
  logoAlt = logoAlt || "Logo";
  const [stiked, setStiked] = useState(false);
  const [open, setOpen] = useState(false);
  const toggleHandler = () => {
    setOpen(!open);
  };
  return (
    <Sticky
      top={0}
      innerZ={5}
      onStateChange={status => setStiked(status.status === Sticky.STATUS_FIXED)}
    >
      <Box variant="navbar" className={stiked ? "sticky" : "sticky-off"}>
        <Container>
          <Flex alignItems="center" justifyContent="center">
            <Box
            py={4}
            sx= {{
              "a": {
                display: "block"
              }
            }}
            >
              <Link href="/">
                <CustomImage
                  height="50px !important"
                  img={menusSettings.logo}
                  alt={logoAlt}
                />
              </Link>
            </Box>
            <Box alignItems="center" display={["none", "none", "none"]}>
              {menusSettings.mainmenu
                .filter(item => item.inNavBar)
                .map((item, i) => (
                  <Link
                    activeClassName="active"
                    href={item.path}
                    external={item.external}
                    key={"menu-" + i}
                  >
                    <Button variant="ninja">
                      <Text pl={[0, 0, 3]}>{item.title}</Text>
                    </Button>
                  </Link>
                ))}
            </Box>
            <Drawer
              closeButton={
                <Box textAlign="right">
                  <Button variant="ninja" p={3}>
                    <Icofont icon="close" size={2} />
                  </Button>
                </Box>
              }
              placement="right"
              drawerHandler={
                <Button variant="ninja" pl={2} display={["none", "none", "none"]}>
                  <Icofont icon="navigation-menu" size={2} />
                </Button>
              }
              open={open}
              toggleHandler={toggleHandler}
            >
              <Box width={["100vw", "100vw", "400px"]} bg="background">
                {menusSettings.mainmenu
                  .filter(item => item.inSideBar)
                  .map((item, i) => (
                    <Link href={item.path} external={item.external} onClick={toggleHandler} key={i}>
                      <Button
                        variant="ninja"
                        px={3}
                        py={3}
                        width="100%"
                        textAlign={["center", "center", "left"]}
                      >
                        <Text pl={[0, 0, 3]}>{item.title}</Text>
                      </Button>
                    </Link>
                  ))}
              </Box>
            </Drawer>
          </Flex>
        </Container>
      </Box>
    </Sticky>
  );
};

NavbarRaw.propTypes = {
  logoAlt: PropTypes.string,
  menusSettings: PropTypes.shape({
    logo: PropTypes.any,
    mainmenu: PropTypes.any
  }),
  theme: PropTypes.shape({
    space: PropTypes.any
  })
};

const Navbar = ({ theme }) => {
  const { markdownRemark, site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
        markdownRemark(frontmatter: { title: { eq: "menus" } }) {
          frontmatter {
            logo {
              childImageSharp {
                fixed(height: 50, quality: 64) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
              publicURL
            }
            mainmenu {
              external
              inNavBar
              inSideBar
              offset
              path
              title
            }
          }
        }
      }
    `
  );

  const menusSettings = markdownRemark.frontmatter || {};
  return (
    <NavbarRaw theme={theme} menusSettings={menusSettings} logoAlt={site.siteMetadata.title} />
  );
};

Navbar.propTypes = {
  theme: PropTypes.any
};

export { NavbarRaw };
export default withTheme(Navbar);
